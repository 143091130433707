import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { Link } from "react-router-dom";
const AdminComission = (props) => {
    const [comission, setComission] = useState(0);
    const comissionInput = useRef(null);
    useEffect(() => {
        axios.get("coinPlan/admin-comission-per-coin").then((res) => {
            console.log(res.data)
            if(res.data && res.data.adminComissionPerCoin){
                setComission(res.data.adminComissionPerCoin);
            } else {
                setComission(0);
            }
        }   );
    }, []);
    const handleSubmit = (e) => {
        e.preventDefault();
        const data = comissionInput.current.value;
        if (data) {
            axios.post("coinPlan/admin-comission-per-coin", { adminComissionPerCoin: data }).then((res) => {
                console.log(res.data)
                if (res.data.status) {
                    alert("Admin Comission updated successfully");
                }
            });
        }
    }
    return (
        <>
            <div className="row py-2">
                <div class="col-2">
                    <h4 className="hostTitle">Admin Comission </h4>
                </div>
                <div class="col-10">
                    <div class="breadcrumb-four float-right">
                        <ul class="breadcrumb">
                            <li>
                                <Link to="/admin/dashboard">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-home"
                                    >
                                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                        <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                    </svg>
                                </Link>
                            </li>

                            <li class="active">
                                <a href={() => false}> Admin comission </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column">
                <form>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="mb-2 text-gray">Comission %</label>
                                <input
                                style={{border: '1px solid #e3e3e3'}}
                                    type="number"
                                    className="form-control"
                                    required=""
                                    value={comission}   
                                    id="txt_coin"
                                    ref={comissionInput}
                                    onChange={(e) => setComission(Number(e.target.value))}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 float-right">
                        <button
                            type="button"
                            class="btn btn-info px-3"
                            onClick={handleSubmit}
                        >
                            Update
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default connect(null, {})(
    AdminComission
);
