import React from "react";
import "./assets/css/PrivacyPolicy.css";

const Privacy = () => {
    return (
        <div className="privacy-container">
            <h1>Privacy Policy</h1>

            <section>
                <h2>Introduction</h2>
                <p>
                    Dreamchat ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains
                    how we collect, use, and protect your personal information when you use our app. We do not o"er
                    any product/service under this Platform outside India and your personal data will primarily be stored
                    and processed in India. By visiting this Platform, providing your information or availing any
                    product/service o"ered on the Platform, you expressly agree to be bound by the terms and conditions
                    of this Privacy Policy, the Terms of Use and the applicable service/product terms and conditions, and
                    agree to be governed by the laws of India including but not limited to the laws applicable to data
                    protection and privacy. If you do not agree please do not use or access our Platform.

                </p>
            </section>

            <section>
                <h2>1. Information We Collect</h2>
                <p>
                    We collect your personal data when you use our platform, services. Some of the information that
                    we may collect includes but is not limited to personal data / information provided to us during
                    sign-up/registering or using our Platform such as name, date of birth, telephone/mobile number,
                    email ID and/or any such information. We may track your behavior, preferences, and other
                    information that you choose to provide on our Platform. This information is compiled and analyzed
                    on an aggregated basis. We will also collect your information related to your transactions on
                    Platform and such third-party business partner platforms. When such a third-party business
                    partner collects your personal data directly from you, you will be governed by their privacy
                    policies. We shall not be responsible for the third-party business partner’s privacy practices or the
                    content of their privacy policies, and we request you to read their privacy policies prior to
                    disclosing any information. If you receive an email, a call from a person/association claiming to
                    be MRM SOFTRWARE seeking any personal data like debit/credit card PIN, net-banking or mobile
                    banking password, we request you to never provide such information. If you have already revealed
                    such information, report it immediately to an appropriate law enforcement agency.
                </p>
                <p>We collect the following types of information:</p>
                <ul>
                    <li>
                        <strong>a. Personal Information</strong>
                        <ul>
                            <li><strong>Name:</strong> To identify and personalize your experience.</li>
                            <li><strong>Email Address:</strong> For account verification, communication, and support.</li>
                            <li><strong>Phone Number:</strong> To verify your identity and provide account recovery options.</li>
                            <li><strong>Photo:</strong> For profile identification purposes.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>b. Device Information</strong>
                        <ul>
                            <li><strong>Device ID:</strong> Used for user registration and authentication. This information is not shared with third parties.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>c. Push Notifications</strong>
                        <ul>
                            <li>We use Firebase Cloud Messaging (FCM) to send push notifications. This service may collect
                                certain data about your device to deliver notifications e"iciently. For more information, refer
                                to Firebase's Privacy Policy.</li>
                        </ul>
                    </li>
                </ul>
            </section>

            <section>
                <h2>2. How We Use Your Information</h2>
                <p>We use the collected information for the following purposes:</p>
                <ul>
                    <li><strong>Account Management:</strong> To create, manage, and authenticate your account.</li>
                    <li><strong>Communication:</strong> To respond to inquiries and send important updates.</li>
                    <li><strong>Notifications:</strong> To send push notifications related to your app usage.</li>
                    <li><strong>Improvement:</strong> To enhance the app's functionality and user experience.</li>
                </ul>
            </section>
            <section>
                <h2>3. Data Sharing and Disclosure</h2>
                <p>We do not share your personal or device information with any third parties. Your data is securely
                    stored and only used as outlined in this Privacy Policy.</p>
            </section>
            <section>
                <h2>4. Data Deletion and Retention</h2>
                <p>You have an option to delete your account by visiting your profile and settings on our Platform ,
                    this action would result in you losing all information related to your account. You may also write
                    to us at the contact information provided below to assist you with these requests. We may in event
                    of any pending grievance, claims, pending shipments or any other services we may refuse or delay
                    deletion of the account. Once the account is deleted, you will lose access to the account. We
                    retain your personal data information for a period no longer than is required for the purpose for
                    which it was collected or as required under any applicable law. However, we may retain data
                    related to you if we believe it may be necessary to prevent fraud or future abuse or for other
                    legitimate purposes. We may continue to retain your data in anonymized form for analytical and
                    research purposes.</p>
            </section>
            <section>
                <h2>5. Data Retention</h2>
                <p>We retain your personal information for as long as necessary to fulfill the purposes outlined in this
                    policy or as required by law. You may request deletion of your data at any time by contacting us.</p>
            </section>
            <section>
                <h2>6. Security Precautions</h2>
                <p>To protect your personal data from unauthorized access or disclosure, loss or misuse we adopt
                    reasonable security practices and procedures. Once your information is in our possession or
                    whenever you access your account information, we adhere to our security guidelines to protect it
                    against unauthorized access and o"er the use of a secure server. However, the transmission of
                    information is not completely secure for reasons beyond our control. By using the Platform, the
                    users accept the security implications of data transmission over the internet and the World Wide
                    Web which cannot always be guaranteed as completely secure, and therefore, there would always
                    remain certain inherent risks regarding use of the Platform. Users are responsible for ensuring the
                    protection of login and password records for their account.</p>
            </section>
            <section>
                <h2>7. Your Rights</h2>
                <p>You have the right to:</p>
                <ul>
                    <li>Access and review the information we hold about you.</li>
                    <li>Request corrections to your personal information.</li>
                    <li>Request the deletion of your account and associated data.</li>
                    <li>To exercise these rights, please contact us at <strong>admin@nxerra.com</strong> .</li>
                </ul>
            </section>
            <section>
                <h2>8. Third-Party Services</h2>
                <p>We use Firebase Cloud Messaging (FCM) for push notifications. Firebase may collect certain nonpersonal information as part of its service. Refer to Firebase’s privacy practices here.</p>
            </section>
            <section>
                <h2>9. Updates to This Privacy Policy</h2>
                <p>We may update this Privacy Policy from time to time. Changes will be e"ective when posted on
                    this page. We encourage you to review this policy periodically for any updates.</p>
            </section>
            <section>
                <h2>10. Consent</h2>
                <p>By visiting our Platform or by providing your information, you consent to the collection, use,
                    storage, disclosure and otherwise processing of your information on the Platform in accordance
                    with this Privacy Policy. If you disclose to us any personal data relating to other people, you
                    represent that you have the authority to do so and permit us to use the information in accordance
                    with this Privacy Policy. You, while providing your personal data over the Platform or any partner
                    platforms or establishments, consent to us (including our other corporate entities, a"iliates,
                    lending partners, technology partners, marketing channels, business partners and other third
                    parties) to contact you through SMS, instant messaging apps, call and/or e-mail for the purposes
                    specified in this Privacy Policy. You have an option to withdraw your consent that you have already
                    provided by writing to the Grievance O"icer at the contact information provided below. Please
                    mention “Withdrawal of consent for processing personal data” in your subject line of your
                    communication. We may verify such requests before acting on our request. However, please note
                    that your withdrawal of consent will not be retrospective and will be in accordance with the Terms
                    of Use, this Privacy Policy, and applicable laws. In the event you withdraw consent given to us
                    under this Privacy Policy, we reserve the right to restrict or deny the provision of our services for
                    which we consider such information to be necessary.</p>
            </section>
            <section>
                <h2>11. Contact Us</h2>
                <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
                <ul>
                    <li><strong>Email:</strong> admin@nxerra.com</li>
                    <li><strong>Phone:</strong> +91 62965 25741</li>
                </ul>
            </section>

        </div>
    );
};


export default Privacy;
